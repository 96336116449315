import React from 'react';

export const WaveBackground: React.FC = () => {
  return (
    <>
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-40 -right-40 w-96 h-96 bg-blue-300 rounded-full opacity-20 blur-3xl animate-pulse"></div>
        <div
          className="absolute w-full h-64 bottom-0 left-0 opacity-30"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23ffffff' fill-opacity='1' d='M0,128L48,144C96,160,192,192,288,186.7C384,181,480,139,576,149.3C672,160,768,224,864,229.3C960,235,1056,181,1152,149.3C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E")`,
            backgroundRepeat: 'repeat-x',
            animation: 'wave 20s linear infinite',
          }}
        ></div>
      </div>
      <style>
        {`
          @keyframes wave {
            0% {
              background-position-x: 0;
            }
            100% {
              background-position-x: 1440px;
            }
          }
        `}
      </style>
    </>
  );
};