import React, { useEffect } from 'react';
import { Waves, ChevronRight, Anchor } from 'lucide-react';
import { WaveBackground } from './WaveBackground';
import { StatsSection } from './StatsSection';
import mixpanel from 'mixpanel-browser';

interface QuizHeroProps {
  onStartQuiz: () => void;
}

export const QuizHero: React.FC<QuizHeroProps> = ({ onStartQuiz }) => {
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Track initial visit to the QuizHero page in Mixpanel
    try {
      mixpanel.track('Hero Page Visited', {
        page_name: 'QuizHero',
      });
    } catch (error) {
      console.error('Mixpanel tracking error:', error);
    }

    // Track initial visit to the QuizHero page in Google Analytics
    try {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: 'QuizHero',
          page_path: '/hero',
        });
      }
    } catch (error) {
      console.error('Google Analytics tracking error:', error);
    }

    return () => {
      window.removeEventListener('resize', () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    };
  }, []);

  const handleStartQuiz = () => {
    try {
      // Track start quiz button click in Mixpanel
      mixpanel.track('Start Quiz Button Clicked', {
        page_name: 'QuizHero',
      });
      // Track start quiz button click in Google Analytics
      if (window.gtag) {
        window.gtag('event', 'button_click', {
          event_category: 'QuizHero',
          event_label: 'Start Quiz',
          value: 1,
        });
      }
    } catch (error) {
      console.error('Analytics tracking error:', error);
    }
    // Trigger the quiz start
    onStartQuiz();
  };

  return (
    <div className="relative min-h-[100dvh] bg-gradient-to-br from-teal-800 via-blue-600 to-cyan-500 flex items-center justify-center px-4 py-8 md:p-8 overflow-x-hidden">
      <WaveBackground />
      <div className="relative max-w-xl w-full text-center px-2">
        <div className="mb-6 md:mb-8">
          <div className="p-3 md:p-4 bg-white/10 rounded-full backdrop-blur-sm border border-white/20 shadow-xl inline-block">
            <Anchor className="w-8 h-8 md:w-12 md:h-12 text-white drop-shadow-lg" />
          </div>
        </div>
        
        <h1 className="text-3xl md:text-6xl font-bold text-white mb-4 md:mb-6 drop-shadow-lg leading-tight">
          Discover Your Open Water Personality
        </h1>
        
        <p className="text-base md:text-xl text-white/90 mb-8 md:mb-12 leading-relaxed max-w-lg mx-auto">
          Are you a bold adventurer, a tranquil seeker, or a social connector? Take the quiz to find out your open water personality!
        </p>
        <button
          onClick={handleStartQuiz}
          className="group relative inline-flex items-center justify-center px-6 md:px-10 py-4 md:py-5 text-base md:text-lg font-medium text-teal-700 bg-white rounded-full hover:bg-teal-50 transition-all duration-300 hover:scale-105 shadow-lg hover:shadow-xl w-full md:w-auto"
        >
          <Waves className="w-4 h-4 md:w-5 md:h-5 mr-2 text-teal-500" />
          <span>Start Your Adventure</span>
          <ChevronRight className="ml-2 w-4 h-4 md:w-5 md:h-5 group-hover:translate-x-1 transition-transform text-teal-500" />
        </button>
        <StatsSection />
      </div>
    </div>
  );
};