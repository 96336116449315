import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App.tsx';
import './index.css';

const domain = 'dev-bzztluen7niqsdan.us.auth0.com';
const clientId = 'G7xpRjUJaRsHNlBrL2mQJVlM8mRgLqJs';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
        scope: 'openid profile email',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      useRefreshTokensFallback={true}
    >
      <App />
    </Auth0Provider>
  </StrictMode>
);