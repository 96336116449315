import React, { useState, useEffect } from 'react';
import { questions } from './data/questions';
import { QuizHeader } from './components/QuizHeader';
import { QuizProgress } from './components/QuizProgress';
import { QuestionCard } from './components/QuestionCard';
import { ResultsCard } from './components/ResultsCard';
import { QuizHero } from './components/QuizHero';
import { calculateSwimStyle } from './utils/quizLogic';
import type { QuizState } from './types/quiz';
import mixpanel from 'mixpanel-browser';

mixpanel.init('2c47998aba06fd211380ab166aea2efc', { 
  debug: true, 
  ignore_dnt: true, 
});

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

function App() {
  const [quizStartTime] = useState(Date.now());
  const [quizState, setQuizState] = useState<QuizState>({
    currentQuestionIndex: 0,
    answers: [],
    isComplete: false,
  });
  const [isQuizStarted, setIsQuizStarted] = useState(false); // Track quiz hero display

  const currentQuestion = questions[quizState.currentQuestionIndex];
  const selectedAnswer = quizState.answers[quizState.currentQuestionIndex] ?? null;

  const handleStartQuiz = () => {
    // Track quiz start from QuizHero in Mixpanel
    mixpanel.track('Quiz Started from Hero', {
      quiz_name: 'open_water_quiz',
    });

    // Track in Google Analytics
    window.gtag?.('event', 'quiz_started_from_hero', {
      quiz_name: 'open_water_quiz',
    });

    setIsQuizStarted(true);
  };

  useEffect(() => {
    if (isQuizStarted) {
      window.gtag?.('event', 'quiz_started', {
        quiz_name: 'open_water_quiz',
        total_questions: questions.length,
      });

      mixpanel.track('Quiz Started', {
        quiz_name: 'open_water_quiz',
        total_questions: questions.length,
      });
    }
  }, [isQuizStarted]);

  const handleSelectAnswer = (answerIndex: number) => {
    const isLastQuestion = quizState.currentQuestionIndex === questions.length - 1;
    const updatedAnswers = [...quizState.answers];
    updatedAnswers[quizState.currentQuestionIndex] = answerIndex;

    mixpanel.track('Question Answered', {
      quiz_name: 'open_water_quiz',
      question_id: currentQuestion.id,
      answer_index: answerIndex,
      question_number: quizState.currentQuestionIndex + 1,
    });

    window.gtag?.('event', 'question_answered', {
      quiz_name: 'open_water_quiz',
      question_id: currentQuestion.id,
      answer_text: currentQuestion.options[answerIndex].text,
      question_number: quizState.currentQuestionIndex + 1,
    });

    if (isLastQuestion) {
      const result = calculateSwimStyle(questions, updatedAnswers);

      mixpanel.track('Quiz Completed', {
        quiz_name: 'open_water_quiz',
        result_style: result.id,
        total_time_seconds: (Date.now() - quizStartTime) / 1000,
      });

      window.gtag?.('event', 'quiz_completed', {
        quiz_name: 'open_water_quiz',
        result: result.title,
        total_time_seconds: (Date.now() - quizStartTime) / 1000,
      });

      setQuizState(prev => ({
        ...prev,
        answers: updatedAnswers,
        isComplete: true,
        result,
      }));
    } else {
      setQuizState(prev => ({
        ...prev,
        answers: updatedAnswers,
        currentQuestionIndex: prev.currentQuestionIndex + 1,
      }));
    }
  };

  const handleRestart = () => {
    mixpanel.track('Quiz Restarted', { quiz_name: 'open_water_quiz' });
    window.gtag?.('event', 'quiz_restarted', { quiz_name: 'open_water_quiz' });
    setQuizState({ currentQuestionIndex: 0, answers: [], isComplete: false });
  };

  if (!isQuizStarted) {
    return <QuizHero onStartQuiz={handleStartQuiz} />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-6 sm:py-12 px-4 sm:px-6">
      <div className="max-w-4xl mx-auto">
        <header>
          <QuizHeader />
        </header>
        {!quizState.isComplete ? (
          <>
            <QuizProgress 
              current={quizState.currentQuestionIndex} 
              total={questions.length} 
            />
            <QuestionCard
              question={currentQuestion}
              selectedAnswer={selectedAnswer}
              onSelectAnswer={handleSelectAnswer}
            />
          </>
        ) : (
          quizState.result && (
            <ResultsCard
              swimStyle={quizState.result}
              onRestart={handleRestart}
            />
          )
        )}
      </div>
    </div>
  );
}

export default App;