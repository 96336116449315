import React from 'react';
import { Question } from '../types/quiz';

interface QuestionCardProps {
  question: Question;
  selectedAnswer: number | null;
  onSelectAnswer: (index: number) => void;
}

export function QuestionCard({ question, selectedAnswer, onSelectAnswer }: QuestionCardProps) {
  return (
    <div className="w-full max-w-3xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300">
      <img 
        src={question.imageUrl} 
        alt={question.text}
        className="w-full h-48 sm:h-64 object-cover"
      />
      <div className="p-4 sm:p-8">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4 sm:mb-6">{question.text}</h2>
        <div className="space-y-3 sm:space-y-4">
          {question.options.map((option, index) => (
            <button
              key={index}
              onClick={() => onSelectAnswer(index)}
              className={`w-full p-3 sm:p-4 text-left rounded-lg transition-all duration-200 ${
                selectedAnswer === index
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-50 hover:bg-gray-100 text-gray-700'
              }`}
            >
              <span className="text-sm sm:text-base font-medium">{option.text}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}