import { Question, SwimStyle } from '../types/quiz';
import { swimStyles } from '../data/swimStyles';

export function calculateSwimStyle(questions: Question[], answers: number[]): SwimStyle {
  if (!questions.length || !answers.length) {
    // Default to Explorer if no answers
    return swimStyles.find(style => style.id === 'explorer')!;
  }

  const styleScores: Record<string, number> = {
    adventurer: 0,
    explorer: 0,
    relaxer: 0,
    competitor: 0
  };

  // Calculate scores based on answers
  answers.forEach((answerIndex, questionIndex) => {
    const question = questions[questionIndex];
    if (!question || answerIndex === undefined) return;
    
    const answer = question.options[answerIndex];
    if (!answer) return;
    
    Object.entries(answer.styles).forEach(([style, score]) => {
      if (styleScores.hasOwnProperty(style)) {
        styleScores[style] += score;
      }
    });
  });

  // Find the style with the highest score
  const topStyle = Object.entries(styleScores).reduce((a, b) => 
    styleScores[a[0]] > styleScores[b[0]] ? a : b
  )[0];

  return swimStyles.find(style => style.id === topStyle)!;
}