import React from 'react';
import { Facebook, Twitter, Mail, ExternalLink } from 'lucide-react';
import { SwimStyle } from '../types/quiz';

interface ResultsCardProps {
  swimStyle: SwimStyle;
  onRestart: () => void;
}

export function ResultsCard({ swimStyle, onRestart }: ResultsCardProps) {
  const shareText = `I'm a ${swimStyle.title}! Take the quiz to find your open water personality!`;
  const shareUrl = typeof window !== 'undefined' ? encodeURIComponent(window.location.href) : '';
  
  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${shareUrl}`,
    email: `mailto:?subject=${encodeURIComponent('My Swim Style Quiz Result')}&body=${encodeURIComponent(`${shareText}\n\n${decodeURIComponent(shareUrl)}`)}`
  };

  const handleRedirect = () => {
    window.location.href = 'https://swimpass.app';
  };

  const handleShare = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="relative w-full h-48 sm:h-64">
        <img
          src={swimStyle.imageUrl}
          alt={swimStyle.title}
          className="w-full h-full object-cover"
        />
      </div>
      
      <div className="p-6 sm:p-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-3 sm:mb-4">{swimStyle.title}</h2>
        <p className="text-base sm:text-lg text-gray-700 leading-relaxed mb-5 sm:mb-7">
          {swimStyle.description}
        </p>

        {swimStyle.traits.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-8">
            {swimStyle.traits.map((trait, index) => (
              <span 
                key={index}
                className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium"
              >
                {trait}
              </span>
            ))}
          </div>
        )}

        <div className="border-t border-gray-100 pt-6 mb-6 sm:mb-8">
          <p className="text-gray-500 text-sm sm:text-base mb-3">
            Share your result:
          </p>
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
            <button
              onClick={() => handleShare(shareLinks.facebook)}
              className="flex items-center justify-center gap-2 bg-[#1877F2] text-white px-4 sm:px-5 py-2 sm:py-2.5 rounded-lg font-medium hover:bg-[#166FE5] transition-colors text-sm sm:text-base"
            >
              <Facebook className="w-5 h-5" />
              Facebook
            </button>
            <button
              onClick={() => handleShare(shareLinks.twitter)}
              className="flex items-center justify-center gap-2 bg-[#1DA1F2] text-white px-4 sm:px-5 py-2 sm:py-2.5 rounded-lg font-medium hover:bg-[#1A94DA] transition-colors text-sm sm:text-base"
            >
              <Twitter className="w-5 h-5" />
              Twitter
            </button>
            <button
              onClick={() => handleShare(shareLinks.email)}
              className="flex items-center justify-center gap-2 bg-gray-600 text-white px-4 sm:px-5 py-2 sm:py-2.5 rounded-lg font-medium hover:bg-gray-700 transition-colors text-sm sm:text-base"
            >
              <Mail className="w-5 h-5" />
              Email
            </button>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
          <button
            onClick={handleRedirect}
            className="flex items-center justify-center gap-2 bg-green-600 text-white px-4 sm:px-5 py-2 sm:py-2.5 rounded-lg font-medium hover:bg-green-700 transition-colors text-sm sm:text-base"
          >
            <ExternalLink className="w-5 h-5" />
            Get SwimPass
          </button>
          <button
            onClick={onRestart}
            className="flex items-center justify-center gap-2 bg-gray-100 text-gray-700 px-4 sm:px-5 py-2 sm:py-2.5 rounded-lg font-medium hover:bg-gray-200 transition-colors text-sm sm:text-base"
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}