import { Question } from '../types/quiz';

export const questions: Question[] = [
  {
    id: 1,
    text: "You're approaching a sparkling lagoon. What's your first thought?",
    imageUrl: "https://images.unsplash.com/photo-1628689529124-3e0db67e984c?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "What treasures might this lagoon hold?", styles: { adventurer: 3, explorer: 2, relaxer: 1, competitor: 0 } },
      { text: "This could be the perfect route for today’s swim.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
      { text: "Finally, a serene spot to let go of everything.", styles: { adventurer: 0, explorer: 1, relaxer: 3, competitor: 0 } },
      { text: "Another arena to test my endurance!", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
  },
  {
    id: 2,
    text: "Which open-water animal do you resonate with most?",
    imageUrl: "https://images.unsplash.com/photo-1619707283662-7cc31593c974?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "Dolphin: agile and playful.", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 1 } },
      { text: "Sea turtle: calm and graceful.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
      { text: "Shark: focused and powerful.", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
      { text: "Seal: social and adaptable.", styles: { adventurer: 2, explorer: 1, relaxer: 2, competitor: 0 } },
    ],
  },
  {
    id: 3,
    text: "Your ideal swim soundtrack is:",
    imageUrl: "https://images.unsplash.com/photo-1625568223092-b42fe05ce30d?q=80&w=2531&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "A beat that matches my powerful strokes.", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
      { text: "Calm ambient music with soft ocean waves.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
      { text: "Nature’s soundscapes—just the water and wind.", styles: { adventurer: 3, explorer: 2, relaxer: 1, competitor: 0 } },
      { text: "An upbeat playlist to keep my energy high.", styles: { adventurer: 2, explorer: 1, relaxer: 0, competitor: 3 } },
    ],
  },
  {
    id: 4,
    text: "You’re lost in a vast open-water expanse. What’s your first reaction?",
    imageUrl: "https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "Excitement! Time to explore uncharted territory.", styles: { adventurer: 3, explorer: 1, relaxer: 0, competitor: 0 } },
      { text: "Stay calm, observe, and chart my bearings.", styles: { adventurer: 1, explorer: 3, relaxer: 2, competitor: 0 } },
      { text: "Take a moment to float and reset.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
      { text: "Test myself—how fast can I find my way back?", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
  },
  {
    id: 5,
    text: "What kind of open-water challenge excites you?",
    imageUrl: "https://images.unsplash.com/photo-1527694545342-6176693ca513?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "A long-distance swim with changing conditions.", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 1 } },
      { text: "Exploring a new underwater ecosystem.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
      { text: "A serene evening swim under the stars.", styles: { adventurer: 0, explorer: 1, relaxer: 3, competitor: 0 } },
      { text: "A high-intensity competition with other swimmers.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
  },
  {
    id: 6,
    text: "If you could master one open-water skill, what would it be?",
    imageUrl: "https://images.unsplash.com/photo-1530702137741-7a2fc56e678a?q=80&w=2667&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
      { text: "Perfect navigation skills.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
      { text: "Exceptional endurance and speed.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
      { text: "A zen-like ability to stay calm in any situation.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 1 } },
      { text: "An instinct for finding hidden gems underwater.", styles: { adventurer: 3, explorer: 2, relaxer: 1, competitor: 0 } },
    ],
  },
  {
    id: 7,
    text: "You spot a mysterious island in the distance. What’s your next move?",
    imageUrl: "https://images.unsplash.com/photo-1634113287384-ae026f7478ef?q=80&w=2430&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
        { text: "Swim toward it immediately to uncover its secrets.", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 0 } },
        { text: "Study its features from afar before making a move.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
        { text: "Float nearby, soaking in the view and tranquility.", styles: { adventurer: 0, explorer: 1, relaxer: 3, competitor: 0 } },
        { text: "See if you can outpace others to reach it first.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
  },
  {
      id: 8,
      text: "The weather shifts dramatically during your swim. How do you respond?",
      imageUrl: "https://images.unsplash.com/photo-1518673201468-69eb19661434?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      options: [
          { text: "Charge forward, embracing the challenge.", styles: { adventurer: 3, explorer: 1, relaxer: 0, competitor: 2 } },
          { text: "Adapt your strokes and navigate safely to shore.", styles: { adventurer: 1, explorer: 3, relaxer: 2, competitor: 0 } },
          { text: "Pause and let the natural shifts guide your movements.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
          { text: "Treat it as an impromptu endurance test.", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
      ],
  },
  {
      id: 9,
      text: "If open-water swimming were a movie, which genre would it be?",
      imageUrl: "https://plus.unsplash.com/premium_photo-1713184646605-ae68c76bcbac?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      options: [
          { text: "An action-packed adventure film.", styles: { adventurer: 3, explorer: 1, relaxer: 0, competitor: 2 } },
          { text: "A mystery filled with discoveries.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
          { text: "A soothing indie drama.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
          { text: "A high-stakes sports documentary.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
      ],
  },
  {
      id: 10,
      text: "You discover an underwater cave entrance. What do you do?",
      imageUrl: "https://images.unsplash.com/photo-1533550936502-72bc81df6770?q=80&w=2593&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      options: [
          { text: "Explore it without hesitation—what’s inside?", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 0 } },
          { text: "Examine it carefully before venturing in.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
          { text: "Admire its beauty from a safe distance.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
          { text: "Use it as a personal challenge—how quickly can you navigate it?", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
      ],
  },
  {
      id: 11,
      text: "What’s your ultimate open-water swimming goal?",
      imageUrl: "https://images.unsplash.com/photo-1513073945753-eb763d2a99de?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      options: [
          { text: "To explore every hidden gem of the ocean.", styles: { adventurer: 3, explorer: 3, relaxer: 0, competitor: 0 } },
          { text: "To master the art of endurance and skill.", styles: { adventurer: 1, explorer: 1, relaxer: 0, competitor: 3 } },
          { text: "To find peace and balance in the water.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
          { text: "To achieve glory in competitions.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
      ],
  },
  {
      id: 12,
      text: "What’s the most important item in your swim gear?",
      imageUrl: "https://images.unsplash.com/photo-1519918726082-2130c2d2a782?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      options: [
          { text: "A compass for navigation.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
          { text: "A timer to measure progress.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
          { text: "A pair of goggles to admire underwater beauty.", styles: { adventurer: 3, explorer: 2, relaxer: 2, competitor: 0 } },
          { text: "Nothing—just me and the water.", styles: { adventurer: 1, explorer: 2, relaxer: 3, competitor: 0 } },
      ],
  },
  {
    id: 13,
    text: "What’s your favorite time to swim in open water?",
    imageUrl: "https://images.unsplash.com/photo-1479723390134-5b376887e5d5?q=80&w=2669&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
        { text: "Early morning, as the sun rises.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
        { text: "Midday, under the bright sun.", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 1 } },
        { text: "Evening, as the water calms.", styles: { adventurer: 0, explorer: 1, relaxer: 3, competitor: 0 } },
        { text: "Anytime I can push myself to the limit.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
},
{
    id: 14,
    text: "If you could swim anywhere in the world, where would it be?",
    imageUrl: "https://images.unsplash.com/photo-1510122464847-ed1effe080f5?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
        { text: "A remote, untouched island.", styles: { adventurer: 3, explorer: 2, relaxer: 0, competitor: 0 } },
        { text: "A famous underwater reef.", styles: { adventurer: 2, explorer: 3, relaxer: 1, competitor: 0 } },
        { text: "A peaceful lake surrounded by mountains.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
        { text: "A competitive long-distance swim route.", styles: { adventurer: 0, explorer: 0, relaxer: 0, competitor: 3 } },
    ],
},
{
    id: 15,
    text: "What makes you return to open water again and again?",
    imageUrl: "https://images.unsplash.com/photo-1447953820122-d32169e93b81?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    options: [
        { text: "The endless opportunities for discovery.", styles: { adventurer: 3, explorer: 2, relaxer: 1, competitor: 0 } },
        { text: "The calm and rejuvenation it brings.", styles: { adventurer: 0, explorer: 2, relaxer: 3, competitor: 0 } },
        { text: "The ability to test and improve myself.", styles: { adventurer: 1, explorer: 0, relaxer: 0, competitor: 3 } },
        { text: "The connection I feel with nature.", styles: { adventurer: 2, explorer: 3, relaxer: 2, competitor: 0 } },
    ],
}];