import { SwimStyle } from '../types/quiz';

export const swimStyles: SwimStyle[] = [
  {
    id: 'adventurer',
    title: 'The Adventurer',
    description: 'You thrive in the unpredictable. Each swim is a journey to discover something new, be it a hidden cove, an uncharted lagoon, or your own limits.',
    imageUrl: 'https://images.unsplash.com/photo-1569439157227-923f7897a26d?w=800&auto=format&fit=crop&q=60',
    traits: ['bold', 'resilient', 'exploratory'],
  },
  {
    id: 'explorer',
    title: 'The Explorer',
    description: 'Curiosity fuels your strokes. You map your path carefully, enjoying every moment as you explore new waters and discover the stories they hold.',
    imageUrl: 'https://images.unsplash.com/photo-1526481280698-8b098c7f62b0?w=800&auto=format&fit=crop&q=60',
    traits: ['curious', 'calm', 'focused'],
  },
  {
    id: 'relaxer',
    title: 'The Tranquil Soul',
    description: 'You swim to find peace. Open water is your sanctuary, a place to relax, rejuvenate, and reconnect with yourself.',
    imageUrl: 'https://images.unsplash.com/photo-1501621737414-4df4b2aab31f?w=800&auto=format&fit=crop&q=60',
    traits: ['serene', 'mindful', 'calming'],
  },
  {
    id: 'competitor',
    title: 'The Competitor',
    description: 'You’re here to win. For you, open-water swimming is a test of skill and endurance. Every challenge fuels your determination.',
    imageUrl: 'https://images.unsplash.com/photo-1518459031867-a89b944bffe4?w=800&auto=format&fit=crop&q=60',
    traits: ['driven', 'intense', 'goal-oriented'],
  },
];