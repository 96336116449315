import React, { useEffect } from 'react';

interface QuizProgressProps {
  current: number;
  total: number;
  quizName?: string;
}

export function QuizProgress({ current, total, quizName }: QuizProgressProps) {
  const progress = total > 0 ? ((current + 1) / total) * 100 : 0;

  useEffect(() => {
    const titleQuizName = quizName || "Quiz";
    document.title = `${titleQuizName} - Question ${current + 1} of ${total}`;
  }, [current, total, quizName]);

  return (
    <div className="w-full mb-6 sm:mb-8 px-4 sm:px-0">
      <div className="flex justify-between text-xs sm:text-sm text-gray-600 mb-2">
        <span>Question {current + 1} of {total}</span>
        <span>{Math.round(progress)}% Complete</span>
      </div>
      <div 
        className="w-full h-1.5 sm:h-2 bg-gray-200 rounded-full overflow-hidden"
        role="progressbar" 
        aria-valuenow={Math.round(progress)}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-label={`Quiz progress: ${Math.round(progress)}% complete`}
      >
        <div 
          className="h-full bg-indigo-600 transition-all duration-300 ease-out"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
}