import React from 'react';

export const StatsSection: React.FC = () => {
  return (
    <div className="mt-16 flex justify-center space-x-8 md:space-x-12">
      <StatCard number="5+" label="Swim Styles" />
      <StatCard number="2m" label="Quick Quiz" />
      <StatCard number="100%" label="Personalized" />
    </div>
  );
};

interface StatCardProps {
  number: string;
  label: string;
}

const StatCard: React.FC<StatCardProps> = ({ number, label }) => {
  return (
    <div className="text-center backdrop-blur-sm bg-white/10 p-4 rounded-xl border border-white/20">
      <p className="text-3xl font-bold text-white">{number}</p>
      <p className="text-sm text-white/90 font-medium">{label}</p>
    </div>
  );
};